import { FaAt, FaLinkedinIn, FaGithub, FaTwitter } from 'react-icons/fa';

const contacts = [
  {
    handle: 'jossdoebler',
    url: 'https://www.linkedin.com/in/jossdoebler/',
    icon: FaLinkedinIn,
  },
  {
    handle: 'jossdoe',
    url: 'https://github.com/jossdoe',
    icon: FaGithub,
  },
  {
    handle: 'mail@joss-doebler.de',
    url: 'mailto:mail@joss-doebler.de',
    icon: FaAt,
  },
];

export default contacts;
