import React from 'react';

import {
  Header,
  Description,
  Contact,
  ContactIcon,
  ContactDetail,
} from './App.style';
import { ReactComponent as LogoSVG } from 'assets/logo.svg';

import contacts from 'static/contacts';

function App() {
  return (
    <Header>
      <LogoSVG />
      <Description>Senior Frontend Engineer</Description>
      <Contact>
        {contacts.map((contact, idx) => (
          <a key={idx} href={contact.url}>
            <section>
              <ContactIcon>
                <contact.icon />
              </ContactIcon>
              <ContactDetail>{contact.handle}</ContactDetail>
            </section>
          </a>
        ))}
      </Contact>
    </Header>
  );
}

export default App;
