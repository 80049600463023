import styled, { css } from 'styled-components';

export const Header = styled.header`
  ${({ theme }) => css`
    background-color: ${theme.backgroundDark};
    color: ${theme.colorLight};
    padding: 50px;
    text-align: center;
    margin-bottom: 50px;

    & > svg {
      display: block;
      max-width: 400px;
      margin: 0 auto;
      margin-bottom: 30px;
    }
  `}
`;

export const Description = styled.div`
  ${({ theme }) => css`
    display: inline-block;
    padding: 20px;
    font-weight: 400;
    position: relative;
    margin-bottom: 30px;
    font-size: 1.2rem;

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 30px;
      right: 30px;
      top: 0;
      height: 3px;
      border-radius: 1.5px;
      background-color: ${theme.backgroundLight};
    }
  `}
`;

export const Contact = styled.div`
  display: flex;
  max-width: 550px;
  margin: 0 auto;
  flex-wrap: wrap;
  padding-left: 10px;

  a {
    flex: 1;
    min-width: 250px;
  }

  section {
    display: flex;
    align-items: center;
    margin-right: 10px;
    margin-bottom: 10px;
    background-color: rgba(255, 255, 255, 0.07);
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.2s;
  }

  section:hover {
    background-color: rgba(255, 255, 255, 0.14);
  }

  section:active {
    transition: all 0.1s;
    transform: scale(0.9);
  }
`;

export const ContactIcon = styled.div`
  ${({ theme }) => css`
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.14);
    color: ${theme.colorLight};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
  `}
`;

export const ContactDetail = styled.div`
  flex: 1;
  text-align: right;
  padding-right: 10px;
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.5);
`;
