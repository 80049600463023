import { createGlobalStyle, css } from 'styled-components';

export const theme = {
  backgroundDark: '#333333',
  backgroundLight: '#eeeeee',
  colorDark: '#333333',
  colorLight: '#eeeeee',
};

export const GlobalStyle = createGlobalStyle`
  ${({ theme }) => css`
    * {
      box-sizing: border-box;
      font-family: 'Inter', sans-serif;
    }

    html,
    body {
      margin: 0;
      padding: 0;
    }

    body {
      background-color: ${theme.backgroundDark};
      color: ${theme.colorDark};
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 100vh;
    }

    a {
      color: inherit;
      text-decoration: none;
    }

    h2 {
      font-size: 2rem;
    }
  `}
`;
